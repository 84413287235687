.tempxy {
  width: 9.2em;
  color: #777;
  padding-left: 2%;
  font-weight: 550;
  height: 2em;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  font-size: small;
  }
  .view-option {
    display: flex;
    justify-content: right;
  }
  body {
    width: 100%;
    min-height: 100vh; /* Ensures background covers full viewport height */
    margin: 0;
    padding: 0;
    background-color: #f5f5f5 !important ;
    box-sizing: border-box;
  }


  
  .containers {
    width: 78vw;
    /* margin: 0 auto; */
    padding: 2em;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  .containers > .ah1 {
    display: flex;
    justify-content: space-between;
    font-size: 30px !important;
    font-weight: 600 !important;
    color: #555 !important;
    margin-bottom: 20px !important;
  }
  
  
  .consultant-form {
    display: grid;
    grid-template-columns: repeat(3, 32.2%);
    /* grid-auto-columns: minmax(, auto); */
    grid-gap: 20px;
  }
  
  .profile-section,
  .contact-address-section,
  .consultant-details-section {
    grid-column: span 3;
    padding: 1em 3em 2.2em;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #ffffff;
  }
  .profile-section {
    display: grid;
    grid-column-gap: 3em;
    grid-row-gap: 2em;
    grid-template-columns: 12em 14em 15.3em 8em;
  }
  
  .contact-address-section {
    display: grid;
    grid-template-columns: 16em 16em 15em;
    grid-column-gap: 4em;
    grid-row-gap: 2em;
  }
  
  .consultant-details-section {
    display: grid;
    grid-template-columns: 16em 16em 15em;
    grid-column-gap: 4em;
    grid-row-gap: 2em;
  }
  
  #consultant-details {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  
  h2 {
    color: #555 !important;
    margin-left: -0.6em !important;
    font-size: 1.2em !important;
    margin-bottom: -0.5rem !important;
  }
  
  #profile {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  #contact-address {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .form-group {
    display: flex;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: space-between;
  }
  
  .form-group label {
    /* flex: 1 0 2vw; */
    /* display: block; */
    /* margin-bottom: 5px; */
    font-size: 12px;
    font-weight: 550;
    color: #555;
  }
  .form-group>div{
    display: flex;
    align-items: center;
  }
  
  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group input[type="file"],
  .form-group input[type="date"],
  .form-group input[type="number"],
  .id-option,
  .form-group select {
    /* flex: 1 0 2; */
    width: 9.2em;
    color: #777;
    height: 2em;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    font-size: 0.8em;
  }
  
  .contact-address-section > div > input[type="text"],
  .consultant-details-section > div > input[type="text"],
  .consultant-details-section > div > select {
    width: 12em;
  }
  
  .form-group input[type="text"]::placeholder,
  .form-group input[type="email"]::placeholder,
  .form-group input[type="file"]::placeholder,
  .form-group input[type="date"]::placeholder,
  .form-group input[type="number"]::placeholder,
  .form-group > select::placeholder {
    width: 9.2em;
    color: #777;
    height: 2em;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    font-size: 0.8em;
  }
  
  #profile-picture {
    text-align: left;
    align-self: left;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 5;
  }
  #profile-picture > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #profile-picture > div > span {
    font-size: smaller;
    color: #777;
  }
  
  #profile-picture > div > img {
    width: 96px;
    height: 96px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #ddd;
  }
  
  .form-actions {
    grid-column: span 3;
    text-align: right;
    margin: 0 0 1rem;
    display: flex;
    justify-content: right;
  }
  
  #proof-type1 {
    width: 8em;
    margin-right: .3em;
  }
  #proof-type {
    width: 9em;
    margin-right: .3em;
  }
  
  .save-btn,
  .cancel-btn {
    flex: 2 1 1;
    padding: 0.7em 2em;
    font-size: 1em;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    margin-right: 2.5em;
    /* width: 11%; */
    font-weight: 600;
  }
  
  .save-btn {
    background-color: #cdf7ee;
    color: #424242;
  }
  
  .cancel-btn {
    background-color: #0e044a;
    color: #f4f4f4;
  }
  
  .save-btn:hover {
    background-color: #cdf7ee;
  }
  
  .cancel-btn:hover {
    background-color: #0e044a;
  }
  
  /* CSS below for EclinicForm  */
  #present-address {
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
    justify-content: space-between;
  }
  #present-address > div {
    font-size: small;
    font-style: italic;
  }
  
  /* CSS below for CF-CP */
  
  #accordion-container {
    /* width:60em; */
    padding: 2.8%;
    display: flex;
    justify-content: stretch;
    flex-wrap: nowrap;
  }
  .accordion-div1 {
    flex: 2 0 500px;
    /* margin: 0 .1em; */
  }
  .accordion-div2 {
    flex: 2 0 300px;
    /* margin: 0 .1em; */
  }