body {
    width: 95%;
    height: 100vh;
    
  }
  
  .toggle-switch {
    display: flex;
    justify-content: center;
    width: 50px;
    height: 25px;
    border-radius: 25px;
    background-color: #ccc;
    position: relative;
    cursor: pointer;
    /* padding: 5px; */
    /* margin-top: 5px; */
    /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  }
  
  .switch {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0);
    font-weight: bold;
    position: absolute;
    top: 2px;
    left: 1px;
    transition: transform 0.3s linear, background-color 0.3s linear;
    border: 0.2rem solid #ccc;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .on {
    background-color: #4caf50;
    transform: translateX(25px);
    -webkit-transform: translateX(25px);
    -moz-transform: translateX(25px);
    -ms-transform: translateX(25px);
    -o-transform: translateX(25px);
  }
  
  .off {
    background-color: #f44336;
    transform: translateX(0);
  }
  
  .switch-state {
    /* margin: 0 10px; */
    text-transform: uppercase;
  }
  