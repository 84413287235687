.prescription-body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.prescription-container {
    background-color: #f4f4f4;
    width: 500px;
    min-height: 700px;
    margin: 20px auto;
    background-color: #fff;
    position: relative;
}

.prescription-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c1d61;
    color: #fff;
    padding: 10px 20px;
}

.prescription-patient-details>div {
    margin-top: 5px;
    font-size: 12px;
    padding: 0px 5px;
    display: flex;
    justify-content: space-between;
    height: 20px;
}

.prescription-pname {
    width: 200px;
}

.prescription-patient-details>div>p>span {
    font-weight: 600;
}

.prescription-section1>h3,
.prescription-section2>h3 {

    margin: 15px 5px 5px 5px;
    font-weight: 600;
}



.prescription-advice {
    display: flex;
    padding: 5px;
    border-bottom: 1px solid #a6a6a6;
    
}

.prescription-advice>p:nth-child(1) {
    font-weight: 600;
}

.prescription-box {
    height: 135px;
    border-top: 1px solid #a6a6a6;
    
    padding: 5px;
    font-size: 13px;

}
.prescription-medicine{
    min-height: 220px;
    margin-top: 10px;

}

table {
    width: 100%;
    border-collapse: collapse;
}

table th,
table td {
    border: 1px solid #949393;
    padding: 5px;
    text-align: left;
}

.prescription-footer {
    position: absolute;
    bottom: 0px;
    margin-top: 10px;
    text-align: start;
    gap: 2px;
    padding: 5px;
    position: relative;
    border-bottom: 10px solid #2c1d61;
}

.prescription-footer>p:nth-child(1) {
    position: absolute;
    right: 5px;
}

.prescription-footer>p>span {
    font-weight: 600;
}





.prescription-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .btn-close,
  .btn-download {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .btn-close {
    background-color: #f44336;
    color: #fff;
  }
  
  .btn-download {
    background-color: #4caf50;
    color: #fff;
  }
  
  .btn-close:hover {
    background-color: #d32f2f;
  }
  
  .btn-download:hover {
    background-color: #388e3c;
  }
  