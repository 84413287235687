.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  box-sizing: border-box;
}
 
.login-form {
  background: #0e044a;
  padding: 2rem;
  border-radius: 27px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  color: white;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}
.login-form > Textfield {
  border: 2px solid white;
  color: aliceblue;
}
 
.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
 
.download-links {
  text-align: center;
}
.download-links section {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjusted gap between icons */
  margin-top: 1rem;
}
 
.download-links img {
  height: 15px;
  border-radius: 3px;
}
.download-links a {
  text-decoration: none;
  color: #f4f4f4;
  font-size: 0.875rem;
  font-weight: 400;
}
/* .download-links section {
  width: 200px;
  justify-content: space-between;
  margin-left: 65px;
} */
 
.login-form .MuiTypography-root {
  color: white;
}
 
.login-form button {
  /* font-family: monda; */
  font-size: 16px;
  margin-top: 1rem;
  font-family: "Monda", sans-serif;
  background-color: #0e044a;
  border-radius: 17px;
  border: 1px solid #fafafa;
  color: #fff9f9;
}
 
.login-form button:hover {
  border-color: #3f6be4;
  background-color: #0e044a;
}
.footer {
  text-align: center;
  margin-top: 1rem;
}
 
.footer img {
  height: 22px;
  margin: 0 3px;
}
/* .login-form .textarea {
  background-color: #f4f4f4;
  border-radius: 15px;
  font-family: "Monda", sans-serif;
}
.textarea::placeholder {
  opacity: 10%;
  font-family: "Monda", sans-serif;
} */
 
textarea,
input[type="text"],
input[type="password"] {
  background-color: #f4f4f4;
  border-radius: 15px;
  font-family: "Monda", sans-serif;
}
 
textarea::placeholder,
input::placeholder {
  font-family: "Monda", sans-serif;
}
 
/* Responsive Styles */
@media (max-width: 768px) {
  .login-container {
    padding: 10px;
  }
 
  .login-form {
    padding: 1rem;
  }
 
  .logo img {
    width: 100px;
  }
 
  .download-links img {
    height: 18px;
  }
 
  .download-links section {
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;
  }
 
  .footer {
    margin-top: 1rem;
  }
}
 
@media (max-width: 480px) {
  .login-container {
    padding: 5px;
  }
 
  .login-form {
    padding: 1rem;
  }
 
  .logo img {
    width: 100px;
  }
 
  .download-links img {
    height: 15px;
  }
 
  .download-links section {
    flex-direction: column;
    gap: 5px;
    margin-top: 0.5rem;
  }
 
  .footer {
    margin-top: 0.5rem;
  }
}
 